<template>
  <MainSettingsEditAdmin
          @changeShippingCompaniesSettings="changeShippingCompaniesSettings"
          :ms="ms"
  />
</template>

<script>
  import MainSettingsEditAdmin from "./MainSettingsEditAdmin/MainSettingsEditAdmin";
  import {
    DHL_PERCENT_BY_SETTING,
    DHL_PERCENT_UA_SETTING,
    DHL_PERCENT_US_SETTING,
    FEDEX_PERCENT_DEFAULT,
    FEDEX_PERCENT_RUSSIA,
    FEDEX_PERCENT_BALTIC,
    OUTPUT_COMMISSION_1000_SETTING,
    OUTPUT_COMMISSION_3000_SETTING,
    OUTPUT_COMMISSION_500_SETTING,
    OUTPUT_KIT_GROUP_MINIMUM_LIMIT_SETTING,
    OUTPUT_UA_CARD_DAY_LIMIT_SETTING,
    OUTPUT_UA_CARD_MONTH_LIMIT_SETTING,
    OUTPUT_UA_CARD_WEEK_LIMIT_SETTING,
    PERCENT_CDEK_DEFAULT,
    TNT_PERCENT_DEFAULT,
    TNT_PERCENT_VIP,
    USER_NEGATIVE_BALANCE_MAX_DAYS,
    USER_NEGATIVE_BALANCE_MIN_LIMIT,
    USER_NEGATIVE_BALANCE_BLOCK_MAX_DAYS,
    USER_NEGATIVE_BALANCE_BLOCK_MIN_LIMIT,
    PERCENT_ORDER_OUTBOUND_INSURANCE_COST,
    ORDER_FEE_PER_ITEM_OVER_THREE,
    FEDEX_PERCENT_POLAND,
    EU_CONSOLIDATION_VAT,
    PRODUCT_CONSOLIDATION_VAT,
    IRELAND_EXPRESS_VAT,
    FEDEX_PERCENT_GB,
    ONE_HOUR_ENGRAVING_COST,
    ENGRAVING_TARIFF_PER_AREA,
    ENGRAVING_TARIFF,
    ENGRAVING_TARIFF_DIFFERENT,
    ENGRAVING_ADJUSTING_THE_LAYOUT,
    ENGRAVING_TARIFF_PER_ORDER,
    ENGRAVING_DISCOUNT,
    NOVAPOSHTA_GLOBAL_PERCENT_DEFAULT,
    UPS_PERCENT_DEFAULT
  } from "../../../../../staticData/staticVariables";
  import {MainSettings} from "../models/MainSettings";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "MainSettingsEdit",

    components: {
      MainSettingsEditAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        ms: new MainSettings(),
      }
    },

    mounted() {
      let myQuery = this.createQueryBuilderObject()

      myQuery.where('settingName',
        `${OUTPUT_KIT_GROUP_MINIMUM_LIMIT_SETTING},
        ${OUTPUT_UA_CARD_DAY_LIMIT_SETTING},
        ${OUTPUT_UA_CARD_WEEK_LIMIT_SETTING},
        ${OUTPUT_UA_CARD_MONTH_LIMIT_SETTING},
        ${OUTPUT_COMMISSION_500_SETTING},
        ${OUTPUT_COMMISSION_1000_SETTING},
        ${OUTPUT_COMMISSION_3000_SETTING},
        ${DHL_PERCENT_UA_SETTING},
        ${DHL_PERCENT_US_SETTING},
        ${DHL_PERCENT_BY_SETTING},
        ${PERCENT_CDEK_DEFAULT},
        ${TNT_PERCENT_DEFAULT},
        ${TNT_PERCENT_VIP},
        ${FEDEX_PERCENT_DEFAULT},
        ${USER_NEGATIVE_BALANCE_MAX_DAYS},
        ${USER_NEGATIVE_BALANCE_MIN_LIMIT},
        ${USER_NEGATIVE_BALANCE_BLOCK_MAX_DAYS},
        ${USER_NEGATIVE_BALANCE_BLOCK_MIN_LIMIT},
        ${PERCENT_ORDER_OUTBOUND_INSURANCE_COST},
        ${ORDER_FEE_PER_ITEM_OVER_THREE},
        ${EU_CONSOLIDATION_VAT},
        ${PRODUCT_CONSOLIDATION_VAT},
        ${IRELAND_EXPRESS_VAT},
        ${ONE_HOUR_ENGRAVING_COST},
        ${ENGRAVING_TARIFF_PER_AREA},
        ${ENGRAVING_TARIFF},
        ${ENGRAVING_TARIFF_DIFFERENT},
        ${ENGRAVING_ADJUSTING_THE_LAYOUT},
        ${ENGRAVING_TARIFF_PER_ORDER},
        ${ENGRAVING_DISCOUNT},
        ${NOVAPOSHTA_GLOBAL_PERCENT_DEFAULT},
        ${FEDEX_PERCENT_RUSSIA},
        ${FEDEX_PERCENT_BALTIC},${FEDEX_PERCENT_POLAND},${FEDEX_PERCENT_GB},${UPS_PERCENT_DEFAULT}`.replaceAll(' ', ''))


      let url = this.generateGetParamsFromQuery(myQuery, 10000, 0)

      this.$store.dispatch('getMainShippingSettings', url).then((response) => {
        this.ms.setShippingCompanies(this.$store.getters.getMainShippingSettings)
        this.ms.setLimitsItem(this.$store.getters.getMainShippingSettings)
        this.ms.setBalanceItem(this.$store.getters.getMainShippingSettings)
        this.ms.setGeneralItem(this.$store.getters.getMainShippingSettings)
        this.ms.setEngravingItem(this.$store.getters.getMainShippingSettings)
        this.ms.setHistoryOfChanged(response.data?.data?.activityUUID)
      }).catch(error => this.createErrorLog(error))
    },

    methods: {
      changeShippingCompaniesSettings(){
        let data = this.ms.prepareData()

        this.$store.dispatch('setMainShippingSettings', data).then((response) => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationStatusChanged')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    },

  }
</script>

<style scoped>

</style>
